import { useTheme } from "@mui/material/styles";
import horizontalLogo from "assets/images/GearCode-horizontal-color.svg";

const HorizontalLogo = () => {
  const theme = useTheme();

  return (
    <img src={horizontalLogo} alt="GearCode" style={{objectFit: "cover", width: '100%'}} />
  );
};

export default HorizontalLogo;
