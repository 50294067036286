import { useTheme } from "@emotion/react";
import { Avatar, Chip, Stack, Typography } from "@mui/material";

const UserAccount = ({ account }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Avatar
        src={account?.avatarUrl}
        sx={{
          ...theme.typography.mediumAvatar,
          backgroundColor: theme.palette.primary.main
        }}
      />

      <Typography component="span" variant="h5">
        {account?.displayName}
      </Typography>
      <Chip label={account?.tenantName} size="small" sx={{background: theme.palette.colorPrimary500, color: theme.palette.colorGray50}} ></Chip>
    </Stack>
  );
};

export default UserAccount;
