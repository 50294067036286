import { Link } from "react-router-dom";

// material-ui
import { ButtonBase, Box } from "@mui/material";

// project imports
import config from "config";
import Logo from "ui-component/Logo";
import HorizontalLogo from "ui-component/HorizontalLogo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <Box sx={{overflow: "hidden"}}>
      <HorizontalLogo />
    </Box>
  </ButtonBase>
);

export default LogoSection;
