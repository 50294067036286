/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(colors) {
    return {
            colorPrimary500: colors?.colorPrimary500,
            colorGray50: colors?.colorGray50,
            colorGray100: colors?.colorGray100,
            colorGray300: colors?.colorGray300,
            colorGray500: colors?.colorGray500,
            colorGray700: colors?.colorGray700,
            mode: 'light',
            common:{
                black: colors?.colorGray900,
                white: colors?.colorGray50
            },
            primary: {
              main: colors?.colorPrimary500,
              light: colors?.colorPrimary300,
              dark: colors?.colorPrimary500,
              contrastText: colors?.colorGray50,
            },
            secondary: {
              main: colors?.colorPrimary500,
              light: colors?.colorPrimary300,
              dark: colors?.colorPrimary500,
              contrastText: colors?.colorGray50,
            },
            error: {
              main: colors?.colorAccentRed,
              light: colors?.colorAccentRed,
              dark: colors?.colorAccentRed,
              contrastText: colors?.colorGray50,
            },
            warning: {
              main: colors?.colorAccentYellow,
              light: colors?.colorAccentYellow,
              dark: colors?.colorAccentYellow,
              contrastText: colors?.colorGray50,
            },
            info: {
              main: colors?.colorPrimary300,
              light: colors?.colorPrimary300,
              dark: colors?.colorPrimary300,
              contrastText: colors?.colorGray50,
            },
            success: {
              main: colors?.colorAccentGreen,
              light: colors?.colorAccentGreen,
              dark: colors?.colorAccentGreen,
              contrastText: colors?.colorGray50,
            },
            background: {
              paper: colors?.colorGray0,
              default: colors?.colorGray0,
            },
    };
}