import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider, Typography } from "@mui/material";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";
import './assets/scss/style.scss';

// project imports
import NavigationScroll from "layout/NavigationScroll";
import { EventBus } from "@gearcode/react-components/lib/Services";
import { useEffect, useState } from "react";
import DashboardContext from "contexts/DashboardContext";
import { useNavigate } from "react-router-dom";
import config from "config";
import { CultureContext } from "@gearcode/react-components/lib/Components";
import UserAccountsLoader from "components/auth/UserAccountsLoader";
import UserContextSwitcher from "components/auth/UserContextSwitcher";
import HttpClientContext from "components/auth/HttpClientContext";
import ErrorDialog from "./components/common/ErrorDialog";
import NotificationsContainer from "./components/common/NotificationsContainer";
import { LicenseInfo } from "@mui/x-license";
import * as events from "./events";

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [errorDialogDetails, setErrorDialogDetails] = useState(null);

  const navigate = useNavigate();

  LicenseInfo.setLicenseKey(
    "6277507135beba94e553071b9bc74cccTz05MTE4NSxFPTE3NDgwOTI2MTAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
  );

  useEffect(() => {
    const defaultRedirect = () => {
      navigate(`/default`);
    };

    const unauthorizedRedirect = () => {
      navigate(
        `/auth/unauthorized?redirectUrl=${encodeURIComponent(location.href)}`
      );
    };

    const forrbidenRedirect = () => {
      navigate(
        `/auth/forbidden?redirectUrl=${encodeURIComponent(location.href)}`
      );
    };

    const httpCallback = (error) => {
      switch (error.httpStatusCode) {
        case 401:
          unauthorizedRedirect();
          break;
        case 403:
          forrbidenRedirect();
          break;
        default:
          setErrorDialogOpen(true);
          setErrorDialogMessage(error.httpStatusCode);
          setErrorDialogDetails(error.message);

          break;
      }
    };

    EventBus.on(events.UNMATCHED_SELECTED_MENU_ITEM, defaultRedirect);
    EventBus.on(events.UNAUTHORIZED, unauthorizedRedirect);
    EventBus.on(events.HTTP_ERROR, httpCallback);

    return () => {
      EventBus.remove(events.UNMATCHED_SELECTED_MENU_ITEM, defaultRedirect);
      EventBus.remove(events.UNAUTHORIZED, unauthorizedRedirect);
      EventBus.remove(events.HTTP_ERROR, httpCallback);
    };
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        apiBaseUrl: `${location.origin}${config.basename}/api/app`,
        appDefaultUrl: `${location.origin}${config.basename}${config.defaultPath}`,
        getAbsoluteUrl: (appRelativePath) => {
          return `${location.origin}${config.basename}${appRelativePath}`;
        },
      }}
    >
      <StyledEngineProvider injectFirst>
        <CultureContext>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <UserAccountsLoader>
                <HttpClientContext></HttpClientContext>
                <UserContextSwitcher>
                  <Routes />
                </UserContextSwitcher>
              </UserAccountsLoader>
            </NavigationScroll>
            <ErrorDialog
              open={errorDialogOpen}
              statusMessage={errorDialogMessage}
              details={errorDialogDetails}
              onClose={() => setErrorDialogOpen(false)}
            />
            <NotificationsContainer />
          </ThemeProvider>
        </CultureContext>
      </StyledEngineProvider>
    </DashboardContext.Provider>
  );
};

export default App;
