import { borderRadius, lineHeight, margin, padding } from "@mui/system";

export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.colors.colorPrimary500,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderRadius: theme.styles.borderRadius,
                    '&.Mui-selected': {
                        color: theme.colors.colorGray50,
                        backgroundColor: theme.colors.colorPrimary500,
                        '&:hover': {
                            backgroundColor: theme.colors.colorPrimary300
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.colors.colorGray50
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.colors.colorPrimary300,
                        color: theme.colors.colorGray50,
                        '& .MuiListItemIcon-root': {
                            color: theme.colors.colorGray50
                        },
                        '& .MuiTypography-root': {
                            color: theme.colors.colorGray50
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: theme.styles.borderRadius
                },
                contained: {
                    backgroundColor: theme.colors.colorPrimary500,
                    '&:hover': {
                        backgroundColor: theme.colors.colorPrimary500
                    }
                },
                outlined: {
                    color: theme.colors.colorPrimary500,
                    borderColor: theme.colors.colorPrimary500,
                    '&:hover': {
                        color: theme.colors.colorGray50,
                        borderColor: theme.colors.colorPrimary500,
                        backgroundColor: theme.colors.colorPrimary500
                    }
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: theme.styles.borderRadius
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.colors.colorGray100,
                    opacity: 1
                }
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    borderRadius: theme.styles.borderRadius,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        padding: '14px',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    borderRadius: theme.styles.borderRadius,
                    "& .MuiTablePagination-displayedRows": {
                        display: "none",
                      },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                main: {
                    borderRadius: theme.styles.borderRadius,
                    backgroundColor: theme.colors.colorGray0,
                    border: `solid 1px ${theme.colors.colorGray50}`
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: theme.styles.borderRadius,
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    borderRadius: theme.styles.borderRadius 
                },
            },
        },
    };
}
