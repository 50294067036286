import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const UserScope = ({ scope, color, onClick }) => {
  const theme = useTheme();

  return (
    <Chip
        color={color || "secondary"}
        size="medium"
        sx={{ width: "100%", '&:hover': {
          color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
            cursor: "pointer",
          },
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`
        }}
        label={scope}
        onClick={onClick}/>
  );
};

export default UserScope;
