/**
 * Shape used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeShape(theme) {
    return {
        borderRadius: theme?.style?.borderRadius,
    };
}
