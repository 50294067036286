import { createTheme } from '@mui/material/styles';

// assets
import colorStyles from 'assets/scss/colors.style.scss';
import commonStyles from 'assets/scss/style.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import themeShape from './shape';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {

    const themeOption = {
        colors: colorStyles,
        styles: commonStyles,
        customization
    };

    const themeOptions = {
        palette: themePalette(colorStyles),
        typography: themeTypography(themeOption),
        shape: themeShape(themeOption)
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
